import { createApp } from 'vue'
import App from './App.vue'

import Axios from 'axios';
const axios = Axios.create({
    baseURL: 'https://chankovapi.azurewebsites.net'
});

import router from './router';

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(router);
app.mount('#app');